.brand-card {
  &__content {
    padding-top: 26px;
    padding-right: var(--emu-common-spacing-medium);
    padding-bottom: var(--emu-common-spacing-large);
    padding-left: var(--emu-common-spacing-medium);
    border-top: var(--emu-common-border-width-thin) solid
      var(--emu-common-colors-black);
    min-height: 400px; // as per figma

    @include mq('large') {
      padding-top: 42px;
      padding-right: var(--emu-common-spacing-brand-medium);
      padding-bottom: var(--emu-common-spacing-brand-medium);
      padding-left: var(--emu-common-spacing-brand-medium);
      min-height: 600px; // as per figma
    }
  }

  &__title {
    margin-bottom: var(--emu-common-spacing-medium);

    @include mq('large') {
      margin-bottom: var(--emu-common-spacing-brand-xs);
    }
  }

  &__subtitle {
    @include mq('large') {
      margin-bottom: var(--emu-common-spacing-brand-xs);
    }

    .cmp-title__text {
      letter-spacing: -0.4px;
    }
  }

  &__txt {
    max-height: 100%;
  }
}
