#undereyes {
  .undereyes {
    &__teaser {
      .content-container {
        padding-top: 38px;

        @include mq('large', 'max-width') {
          padding-bottom: 17px;
        }

        @include mq('large') {
          padding-top: var(--emu-common-spacing-brand-medium);
        }

        .emu-teaser {
          &__pretitle {
            @include mq('large') {
              margin-bottom: 22px;
            }
          }

          &__description {
            @include mq('large') {
              max-width: 426px; // to match design
            }
          }

          &__disclaimer {
            @include mq('large', 'max-width') {
              margin-top: 29px;
            }
          }
        }
      }
    }

    &__intro-box {
      padding-top: 39px;
      padding-bottom: var(--emu-common-spacing-brand-xs);

      @include mq('large') {
        padding-top: 61px;
        padding-bottom: 79px;
      }

      .intro-box {
        &__title {
          @include mq('large') {
            max-width: unset;
          }
        }
      }
    }

    &__ba-item {
      .ba-item {
        &__content {
          padding-top: 39px;

          @include mq('large') {
            padding-top: 54px;
            padding-bottom: 57px;
          }
        }

        &__assets {
          @include mq('large', 'max-width') {
            border-bottom: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-black);
          }
        }
      }
    }

    &__img-txt-grid {
      .img-txt-grid {
        &__title {
          margin-top: 21px;
          margin-bottom: 10px;

          @include mq('large') {
            margin-top: 39px;
            margin-bottom: var(--emu-common-spacing-small);
          }
        }

        &__text {
          margin-bottom: 22px;
          margin-top: 7px;
        }

        &__section {
          &--content {
            padding-top: 17px;

            @include mq('large') {
              padding-top: 26px;
            }
          }
        }
      }
    }

    &__benefits-intro-box {
      padding-top: var(--emu-common-spacing-brand-small);
      padding-bottom: 55px;

      @include mq('large') {
        padding-top: 60px;
        padding-bottom: 103px;
      }

      .intro-box {
        &__title {
          margin-bottom: 17px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-small);
          }
        }
      }
    }

    &__three-card-grid {
      > .container {
        &:last-child {
          .brand-card {
            &__content {
              @include mq('large', 'max-width') {
                border-bottom: var(--emu-common-border-width-thin) solid
                  var(--emu-common-colors-black);
              }
            }
          }
        }
      }

      .brand-card {
        &__content {
          padding-top: 31px;

          @include mq('large', 'max-width') {
            padding-bottom: 31px;
          }

          @include mq('large') {
            padding-top: 50px;
          }
        }

        &__title {
          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-small);
          }
        }

        &__txt {
          @include mq('large', 'max-width') {
            margin-bottom: 25px;
          }
        }

        &__disc {
          p {
            sup {
              font-size: 100%;
              top: 5px;
            }
          }
        }
      }
    }
  }

  .faq {
    &__section {
      &--left {
        @include mq('large', 'max-width') {
          padding-top: 62px;
        }
      }
    }

    &__description {
      margin-bottom: 25px;
    }

    &__filter-cta-container {
      @include mq('large') {
        margin-top: 25px;
      }
    }
  }
}
