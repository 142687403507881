#lips {
    .lips {
      &__teaser {
        .content-container {
          padding-top: 38px;
  
          @include mq('large') {
            padding-top: var(--emu-common-spacing-brand-medium);
            padding-bottom: 109px;
          }
  
          .emu-teaser {
            &__pretitle {
              @include mq('large') {
                margin-bottom: 21px;
              }
            }
  
            &__description {
              @include mq('large') {
                max-width: 411px; //to match design
              }
            }
          }
        }
      }
  
      &__intro-box {
        padding-top: var(--emu-common-spacing-brand-small);
        padding-bottom: var(--emu-common-spacing-brand-xs);
  
        @include mq('large') {
          padding-top: 62px;
          padding-bottom: 78px;
        }
      }
  
      &__ba-item {
        .ba-item {
          &__content {
            padding-top: 39px;
  
            @include mq('large') {
              padding-top: 54px;
            }
          }
        }
      }
  
      &__img-txt-grid {
        .img-txt-grid {
          &__title {
            margin-top: 21px;
            margin-bottom: 12px;
  
            @include mq('large') {
              margin-top: 39px;
              margin-bottom: 9px;
            }
          }
  
          &__text {
            margin-bottom: 22px;
            margin-top: var(--emu-common-spacing-xs);
          }
  
          &__section {
            &--content {
              padding-top: 17px;
              padding-bottom: var(--emu-common-spacing-none);
  
              @include mq('large') {
                padding-top: 26px;
              }
            }
          }
        }
      }
  
      &__benefits-intro-box {
        padding-top: 39px;
        padding-bottom: 55px;
  
        @include mq('large') {
          padding-top: 60px;
          padding-bottom: 103px;
        }
  
        .intro-box {
          &__title {
            margin-bottom: 17px;
  
            @include mq('large') {
              margin-bottom: 7px;
            }
          }
        }
      }
  
      &__three-card-grid {
        .brand-card {
          &__content {
            padding-top: 31px;
  
            @include mq('large') {
              padding-top: 51px;
            }
          }
  
          &__title {
            @include mq('large') {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  
    .faq {
      &__section {
        &--right {
          .plus-minus-icon .aaaem-accordion__icon {
            margin-left: 29px;
          }
        }
  
        &--left {
          @include mq('large', 'max-width') {
            padding-top: var(--emu-common-spacing-xl);
          }
        }
      }
  
      &__description {
        margin-bottom: var(--emu-common-spacing-brand-xs);
      }
  
      &__filter-cta-container {
        @include mq('large') {
          margin-top: var(--emu-common-spacing-brand-xs);
        }
      }
    }
  }