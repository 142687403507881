#full-face {
  .full-face {
    &__hero-teaser {
      .content-container {
        padding-top: 38px;

        @include mq('large') {
          padding-top: var(--emu-common-spacing-brand-medium);
        }

        .emu-teaser {
          &__disclaimer {
            margin-top: 29px;
          }

          &__pretitle {
            @include mq('large') {
              margin-bottom: 21px;
            }
          }
        }
      }
    }

    &__intro {
      padding-top: 39px;
      padding-bottom: 46px;

      @include mq('large') {
        padding-top: 62px;
        padding-bottom: 75px;
      }

      .intro-box {
        &__title {
          margin-bottom: var(--emu-common-spacing-medium);

          @include mq('large') {
            max-width: unset;
            margin-bottom: 6px;
          }
        }

        &__text {
          padding-right: 13px;
        }
      }
    }

    &__product-tabs {
      .aaaem-tabs {
        &__tab {
          a {
            @include mq('large') {
              padding-bottom: 19px;
              font-size: var(--emu-common-font-sizes-headline-medium);
              line-height: var(--emu-common-line-heights-narrow-large);
              letter-spacing: -0.2px;
            }
          }
        }
      }
    }

    &__product-img-txt-grid {
      .img-txt-grid {
        &__section {
          &--content {
            padding-top: 23px;
            padding-bottom: 22px;
            padding-left: var(--emu-common-spacing-medium);
            padding-right: var(--emu-common-spacing-medium);

            @include mq('large') {
              padding-top: 33px;
              padding-bottom: var(--emu-common-spacing-brand-medium);
              padding-left: var(--emu-common-spacing-brand-medium);
              padding-right: var(--emu-common-spacing-brand-medium);
            }
          }
        }

        &__title {
          margin-top: 14px;
          margin-bottom: 18px;

          @include mq('large') {
            margin-top: 31px;
            margin-bottom: var(--emu-common-spacing-medium);
          }
        }

        &__text {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }

    &__journey-tabs {
      .aaaem-tabs {
        &__tablist {
          padding-top: 30px;
        }

        &__tab {
          a {
            @include mq('large') {
              padding-bottom: 18px;
              font-size: var(--emu-common-font-sizes-headline-medium);
              line-height: var(--emu-common-line-heights-narrow-large);
              letter-spacing: -0.2px;
            }
          }
        }
      }

      .journey {
        .tns-outer {
          border: none;
          border-top: var(--emu-common-border-width-thin) solid
            var(--emu-common-colors-black);
        }

        &__banner-title {
          @include mq('large', 'max-width') {
            margin-bottom: var(--emu-common-spacing-small);
          }
        }

        &__banner-disc {
          p + p {
            margin-top: 18px;
          }
        }

        &__card-content {
          @include mq('large') {
            padding-bottom: var(--emu-common-spacing-none);
            min-height: 345px; // as per figma
          }
        }

        &__card-title {
          @include mq('large', 'max-width') {
            margin-bottom: var(--emu-common-spacing-small);
          }
        }

        &__card-img {
          img {
            @include mq('large', 'max-width') {
              object-position: left top;
            }
          }
        }

        &__card-txt,
        &__card-disc {
          b {
            @include mq('large') {
              font-size: var(--emu-common-font-sizes-body-small);
            }
          }
        }
      }
    }

    &__benefits-intro {
      padding-top: 39px;
      padding-bottom: 54px;

      @include mq('large') {
        padding-top: 60px;
        padding-bottom: 23px;
      }

      .intro-box {
        &__title {
          margin-bottom: 17px;

          @include mq('large') {
            margin-bottom: 7px;
          }
        }
      }
    }

    &__card-carousel {
      padding-bottom: var(--emu-common-spacing-none);

      .emu-carousel {
        &__action {
          @include mq('large') {
            margin-top: -7px;
          }

          &-next {
            right: 52px;
          }

          &-prev {
            left: 52px;
          }
        }
      }

      .tns-nav {
        top: -76px;
        left: 80px;

        @include mq('large') {
          top: -51px;
          left: auto;
          right: 26px;
        }
      }

      .brand-card {
        width: 336px; // as per figma
        border-right: var(--emu-common-border-width-thin) solid
          var(--emu-common-colors-black);

        @include mq('large') {
          width: 460px; // as per figma
        }

        &__content {
          min-height: 501px; // to match figma
          padding-top: 31px;

          @include mq('large') {
            min-height: 601px; // to match figma
            padding-top: 52px;
          }
        }

        &__title {
          @include mq('large') {
            margin-bottom: 6px;
          }
        }
      }
    }
  }

  .pre-footer {
    .img-txt-grid {
      &__section {
        &--content {
          @include mq('large', 'max-width') {
            padding-top: 38px;
          }

          @include mq('large') {
            padding-bottom: 58px;
          }
        }
      }
    }
  }
}
