#savings {
  .alle {
    &__teaser {
      .teaser-container {
        .content-container {
          padding-top: 46px;

          @include mq('large') {
            padding-top: var(--emu-common-spacing-brand-medium);
            padding-bottom: 109px;
          }

          .title-lockup {
            @include mq('large') {
              max-width: 356px; // to match with design
            }
          }

          .emu-teaser {
            &__pretitle {
              margin-bottom: 21px;
            }

            &__title {
              @include mq('large', 'max-width') {
                margin-bottom: var(--emu-common-spacing-large);
              }
            }

            &__disclaimer {
              @include mq('large') {
                padding-top: 25px;
                padding-bottom: 20px;
              }
            }
          }
        }
      }
    }

    &__page-banner {
      > .container {
        padding-top: 35px;
        padding-right: 15px;
        padding-bottom: 25px;
        padding-left: var(--emu-common-spacing-medium);

        @include mq('large') {
          padding-top: 37px;
          padding-bottom: 28px;
          padding-left: var(--emu-common-spacing-brand-medium);
          padding-right: var(--emu-common-spacing-brand-medium);
        }
      }

      .page-banner {
        &__inner {
          > .container {
            &:last-child {
              flex: 1;
              align-self: start;
            }
          }
        }

        &__cta-container {
          justify-content: flex-end;
        }
      }
    }

    &__intro {
      &.intro-box {
        border-bottom: var(--emu-common-border-width-thin) solid
          var(--emu-common-colors-black);
        padding-top: 39px;
        padding-bottom: var(--emu-common-spacing-brand-xs);

        @include mq('large') {
          padding-top: 60px;
          padding-bottom: 77px;
        }

        &__title {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }
    }

    &__split-grid {
      .img-txt-grid {
        &__title {
          padding-right: 1px;

          &-container {
            margin-bottom: 18px;

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-medium);
            }
          }
        }

        &__text {
          margin-bottom: var(--emu-common-spacing-brand-xs);
        }

        &__content {
          padding-top: 38px;

          @include mq('large') {
            padding-top: 65px;
          }

          &--bottom {
            @include mq('large') {
              padding-top: 54px;
            }
          }
        }

        &__disc {
          &-title {
            margin-bottom: var(--emu-common-spacing-small);

            @include mq('large') {
              margin-bottom: 7px;
            }
          }

          &-text {
            margin-bottom: var(--emu-common-spacing-brand-small);

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-brand-xs);
            }
          }

          &-cta {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }

      &-treat {
        .img-txt-grid {
          &__content {
            &--bottom {
              padding-top: 38px;

              @include mq('large') {
                padding-top: 54px;
              }
            }
          }
        }
      }

      &-save {
        .img-txt-grid {
          &__content {
            @include mq('large', 'max-width') {
              padding-top: 37px;
            }

            &--bottom {
              padding-top: 38px;
              padding-bottom: var(--emu-common-spacing-none);

              @include mq('large') {
                padding-top: 52px;
                padding-bottom: var(--emu-common-spacing-large);
              }
            }
          }

          &__text {
            @include mq('large', 'max-width') {
              margin-bottom: 25px;
            }
          }
        }
      }
    }

    &__fillers {
      .img-txt-grid {
        &__title {
          margin-bottom: 18px;

          @include mq('large') {
            margin-bottom: var(--emu-common-spacing-medium);
          }
        }

        &__text {
          p {
            font-size: var(--emu-common-font-sizes-body-medium);
            line-height: var(--emu-common-line-heights-narrow-xxxl);
          }
        }

        &__cta {
          margin-bottom: var(--emu-common-spacing-large);
        }

        &__section {
          &--content {
            padding-top: 37px;

            @include mq('large') {
              padding-top: 65px;
              border-bottom: var(--emu-common-border-width-thin) solid
                var(--emu-common-colors-black);
            }
          }
        }
      }
    }

    &__promo {
      padding-top: var(--emu-common-spacing-medium);

      @include mq('large') {
        padding-top: var(--emu-common-spacing-brand-medium);
        padding-bottom: 55px;
      }

      .promo {
        &__content {
          padding-top: var(--emu-common-spacing-brand-medium);
          padding-bottom: 48px;
          position: relative;
          z-index: var(--emu-common-other-z-index-layer);

          @include mq('large') {
            padding-bottom: var(--emu-common-spacing-brand-medium);
          }
        }

        &__img-container {
          margin-bottom: var(--emu-common-spacing-none);

          @include mq('large') {
            position: absolute;
            left: var(--emu-common-spacing-xxs);
          }

          img {
            margin: auto;

            @include mq('large') {
              min-height: 493px; //as per design
            }
          }
        }

        &__title {
          .emu-title__text {
            margin-bottom: 45px;
            font-weight: var(--emu-common-font-weight-regular);

            @include mq('large') {
              margin-bottom: 34px;
            }
          }
        }

        &__txt {
          margin-bottom: var(--emu-common-spacing-brand-xs);

          @include mq('large') {
            margin-bottom: 39px;
          }

          p {
            @include mq('large') {
              letter-spacing: -1.15px;
            }
          }
        }
      }

      &-block {
        @include mq('large') {
          display: flex;
          padding-left: var(--emu-common-spacing-brand-medium);
          position: relative;
        }

        > .container {
          @include mq('large') {
            flex: 1;
          }
        }
      }

      &-logo {
        margin-bottom: 49px;

        @include mq('large') {
          margin-bottom: 43px;
        }
      }

      &-links {
        display: flex;
        flex-direction: row;
      }

      &-link {
        &--apple {
          margin-right: 13px;

          @include mq('large') {
            margin-right: var(--emu-common-spacing-brand-xs);
          }

          img {
            @include mq('large') {
              width: 174px; // to match the design
            }
          }
        }

        &--gp {
          img {
            @include mq('large') {
              width: 196px; // to match the design
            }
          }
        }
      }
    }

    &__find-specialist {
      &.resources-teaser {
        .teaser-container {
          .content-container {
            padding-top: 38px;
            padding-bottom: 10px;

            @include mq('large') {
              padding-top: 61px;
            }

            .title-lockup {
              .emu-teaser__title {
                margin-bottom: 17px;

                @include mq('large') {
                  max-width: 615px; //to match with design
                  margin-bottom: 22px;
                }
              }
            }

            .emu-teaser {
              &__subtext {
                margin-top: 22px;
              }

              &__disclaimer {
                @include mq('large') {
                  padding-bottom: 50px;
                }
              }
            }
          }
        }
      }
    }
  }
}
