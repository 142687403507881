.error {
  padding-top: var(--emu-common-spacing-large);
  padding-bottom: 57px;

  @include mq('large') {
    padding-top: 58px;
    padding-bottom: 106px;
  }

  &__pre-title {
    margin-bottom: var(--emu-common-spacing-medium);
    letter-spacing: 2px;

    @include mq('large') {
      margin-bottom: 27px;
    }
  }

  &__title {
    margin-bottom: 17px;

    @include mq('large') {
      margin-bottom: 6px;
      max-width: 88%; // as per design
    }

    .cmp-title__text.cmp-title__text {
      letter-spacing: -0.1px;

      @include mq('large') {
        letter-spacing: -1.1px;
      }
    }

    p + p {
      margin-top: var(--emu-common-spacing-brand-small);

      @include mq('large') {
        margin-top: 62px;
      }
    }
  }

  &__txt {
    @include mq('large') {
      max-width: 464px; // As per figma
    }
  }
}
