header.experiencefragment {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  float: none !important;
  z-index: var(--emu-common-other-z-index-header);
  border-bottom: var(--emu-common-border-width-thin) solid
    var(--emu-common-colors-brand-grey-100);
  transition: all var(--emu-common-other-time-transition-short);
  transform: translateY(0);

  #remediationBanner {
    display: none;
  }
}

.header {
  &__top-bar {
    padding-left: var(--emu-common-spacing-brand-xs);
    padding-right: var(--emu-common-spacing-brand-xs);

    @include mq('389px', 'max-width') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    &-text {
      font-size: var(--emu-common-font-sizes-captions-medium);
      line-height: var(--emu-common-line-heights-narrow-jumbo);

      @include mq('large') {
        font-size: var(--emu-common-font-sizes-body-small);
      }

      ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        li {
          position: relative;
          margin-right: var(--emu-common-spacing-medium);
          margin-left: var(--emu-common-spacing-medium);
          margin-top: 10.5px;
          margin-bottom: 10.5px;

          @include mq('large') {
            margin-right: var(--emu-common-spacing-brand-xs);
            margin-left: var(--emu-common-spacing-brand-xs);
            margin-top: 7.5px;
            margin-bottom: 7.5px;
          }

          &:not(:first-child) {
            &::before {
              content: '';
              position: absolute;
              display: block;
              width: var(--emu-common-sizing-xxs);
              height: 20px; // as per figma
              background-color: var(--emu-common-colors-white);
              opacity: 0.4; // as per figma
              left: -16.5px;
              top: -2.5px;

              @include mq('large') {
                top: 0;
                left: -24.5px;
              }
            }
          }
        }
      }
    }
  }

  &__main {
    position: relative;

    @include mq('large') {
      display: flex;
      align-items: center;
      padding-top: 11px;
      padding-right: var(--emu-common-spacing-medium);
      padding-bottom: 11px;
      padding-left: var(--emu-common-spacing-medium);
    }

    > .container:last-child {
      flex: auto;
    }
  }

  &__logo-container {
    padding-top: 12px;
    padding-right: var(--emu-common-spacing-medium);
    padding-bottom: 9px;
    padding-left: 12px;
    display: flex;
    align-items: center;

    @include mq('large') {
      padding-top: var(--emu-common-spacing-none);
      padding-bottom: var(--emu-common-spacing-none);
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
    }

    > .container:last-child {
      margin-left: auto;
    }
  }

  &__menu-toggle-cta-container {
    position: relative;

    @include mq('large') {
      display: none;
    }

    > .button {
      display: flex;
      position: static;
    }
  }

  &__menu-toggle-cta {
    // increasing specificity
    &.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
      padding: var(--emu-common-spacing-none);
      transition: none;

      &,
      &:active,
      &:focus,
      &:hover {
        background-color: var(--emu-common-colors-transparent);
        outline: none;
        border: none;
      }
    }

    &--close {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;

      // increasing specificity
      &.emu-button-v2.emu-button-v2.emu-button-v2 {
        display: none;
      }
    }
  }

  &__logo-wrapper {
    // increasing specificity
    &.aaaem-container {
      margin-left: 14px;

      @include mq('large') {
        margin-left: var(--emu-common-spacing-none);
      }
    }

    img {
      height: 42px; // as per figma

      @include mq('large') {
        position: relative;
        top: 2px; // needed to match the design more closer
      }
    }
  }

  &__cta-container {
    transition: all var(--emu-common-other-time-transition-short) linear;

    @include mq('large') {
      display: none;
    }

    // increasing specificity
    .emu-button-v2.emu-button-v2 {
      padding-top: 8.5px;
      padding-right: 11px;
      padding-bottom: 8.5px;
      padding-left: 11px;
    }
  }

  &__nav-container {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 100dvh;
    transform: translateY(-100%);
    transition: all var(--emu-common-other-time-transition-short) linear;
    opacity: 0;
    pointer-events: none;
    z-index: var(--emu-common-other-z-index-behind);

    @include mq('large') {
      display: flex;
      align-items: center;
      flex-direction: row;
      position: static;
      width: auto;
      height: auto;
      opacity: 1;
      pointer-events: initial;
      transform: none;
      z-index: var(--emu-common-other-z-index-header);
    }

    // navigation list and Find a Provider button containers
    > div {
      padding-top: 10px;
      padding-bottom: var(--emu-common-spacing-medium);
      padding-left: var(--emu-common-spacing-medium);
      padding-right: var(--emu-common-spacing-medium);
      overflow: auto;

      @include mq('large') {
        padding-top: var(--emu-common-spacing-none);
        padding-bottom: var(--emu-common-spacing-none);
        padding-left: 12px;
        padding-right: var(--emu-common-spacing-none);
        overflow: visible;
      }
    }

    > .navigationlist {
      height: calc(
        100dvh - var(--headerHeight) - var(--headerFindAProviderCTAHeight) +
          var(--headerPageBannerHeight, 0)
      ); // 100 viewport height minus the header's height and the height of the CTA inside the menu and adding the banner height

      @include mq('large') {
        height: auto;
      }
    }

    // container of the find a provider CTA
    > .container {
      margin-top: auto;

      @include mq('large') {
        margin-top: unset;
        margin-left: auto;
      }
    }
  }

  &__nav-cta {
    // increasing specificity
    &.emu-button-v2.emu-button-v2 {
      width: 100%;
      justify-content: center;

      @include mq('large') {
        padding-top: 8.5px;
        padding-bottom: 8.5px;
      }
    }
  }
}

// when mobile menu is in open state
.u-page--mobile-menu-open {
  @include mq('large', 'max-width') {
    overflow: hidden;
    height: 100dvh;
  }

  header.experiencefragment {
    // moving the header so that the banner is hidden
    @include mq('large', 'max-width') {
      transform: translateY(calc(-1 * var(--banner-scrolled-diff, 0)));
    }
  }

  .header__nav-container {
    transform: translateY(0);
    opacity: 1;
    pointer-events: initial;

    @include mq('large') {
      transform: none;
      opacity: 1;
      pointer-events: initial;
    }
  }

  .header {
    &__menu-toggle-cta {
      // hiding the menu open trigger
      &--open {
        opacity: 0;
      }

      // showing the menu open trigger
      &--close {
        // increasing specificity
        &.emu-button-v2.emu-button-v2 {
          display: inline-flex;
        }
      }
    }

    &__cta-container {
      opacity: 0;
      pointer-events: none;
    }

    &__nav-container {
      @include mq('large', 'max-width') {
        display: block;
      }
    }
  }
}

// when the page is scrolled back up by 40px, show the header without the top bar section
.u-page--scrolled-up-40 {
  header.experiencefragment {
    transform: translateY(calc(-1 * var(--headerTopBarHeight)));
  }

  .header__nav-container > .navigationlist {
    height: calc(
      100dvh -
        (
          var(--headerHeight) - var(--headerTopBarHeight) -
            var(--headerPageBannerHeight)
        ) - var(--headerFindAProviderCTAHeight)
    );

    @include mq('large') {
      height: auto;
    }
  }
}

// when page is scrolled
.u-page--scrolled-down {
  // hide the header
  header.experiencefragment {
    transform: translateY(-100%);
  }
}

//when dvh won't support
.not-supports-dvh {
  .header__nav-container {
    @include mq('large', 'max-width') {
      height: calc(
        100vh - var(--headerHeight) - var(--headerPageBannerHeight, 0)
      );
    }

    > .navigationlist {
      @include mq('large', 'max-width') {
        height: auto;
      }
    }
  }
}
