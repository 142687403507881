.img-txt-grid {
  &__count {
    margin-bottom: 26px;
    width: 23px;
    height: 23px;
  }

  &__title .cmp-title__text {
    @include mq('large', 'max-width') {
      font-size: var(--emu-common-font-sizes-headline-large);
      line-height: var(--emu-common-line-heights-custom-regular);
    }
  }

  &__disc {
    margin-top: var(--emu-common-spacing-none);
  }

  &__title-container {
    @include mq('large', 'max-width') {
      margin-bottom: var(--emu-common-spacing-brand-xs);
    }
  }
}
