#jawline {
    .jawline {
      &__teaser {
        .content-container {
          padding-top: 38px;
  
          @include mq('large', 'max-width') {
            padding-bottom: 17px;
          }
  
          @include mq('large') {
            padding-top: var(--emu-common-spacing-brand-medium);
          }
  
          .emu-teaser {
            &__pretitle {
              @include mq('large') {
                margin-bottom: 22px;
              }
            }
  
            &__title {
              margin-bottom: var(--emu-common-spacing-large);
            }
          }
        }
      }
  
      &__intro-box {
        padding-top: 39px;
        padding-bottom: 25px;
  
        @include mq('large') {
          padding-top: 61px;
          padding-bottom: 78px;
        }
      }
  
      &__ba-item {
        .ba-item {
          &__content {
            padding-top: 39px;
  
            @include mq('large') {
              padding-top: 54px;
            }
          }
  
          &__assets {
            @include mq('large', 'max-width') {
              border-bottom: var(--emu-common-border-width-thin) solid
                var(--emu-common-colors-black);
            }
          }
        }
      }
  
      &__img-txt-grid {
        .img-txt-grid {
          &__title {
            margin-bottom: 18px;
  
            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-medium);
            }
          }
  
          &__text {
            margin-bottom: 21px;
  
            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-brand-xs);
            }
          }
  
          &__section {
            &--content {
              padding-top: 37px;
  
              @include mq('large') {
                padding-top: 65px;
              }
            }
          }
        }
      }
  
      &__benefits-intro-box {
        padding-top: var(--emu-common-spacing-brand-small);
        padding-bottom: 55px;
  
        @include mq('large') {
          padding-top: 61px;
          padding-bottom: 103px;
        }
  
        .intro-box {
          &__title {
            margin-bottom: 17px;
  
            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-small);
            }
          }
        }
      }
  
      &__three-card-grid {
        > .container {
          &:first-child {
            .brand-card {
              &__content {
                @include mq('large', 'max-width') {
                  padding-bottom: 37px;
                }
              }
            }
          }
  
          &:last-child {
            .brand-card {
              &__content {
                @include mq('large', 'max-width') {
                  border-bottom: var(--emu-common-border-width-thin) solid
                    var(--emu-common-colors-black);
                }
              }
            }
          }
        }
  
        .brand-card {
          &__content {
            padding-top: 31px;
  
            @include mq('large', 'max-width') {
              padding-bottom: 31px;
            }
  
            @include mq('large') {
              padding-top: 52px;
            }
          }
  
          &__title {
            margin-bottom: var(--emu-common-spacing-medium);
  
            @include mq('large') {
              margin-bottom: 6px;
            }
          }
  
          &__txt {
            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-brand-xs);
            }
          }
        }
      }
    }
  
    .faq {
      &__section {
        &--right {
          .plus-minus-icon .aaaem-accordion__icon {
            margin-left: 65px;
          }
        }
  
        &--left {
          padding-top: 62px;
  
          @include mq('large') {
            padding-top: 60px;
          }
        }
      }
  
      &__description {
        margin-bottom: 25px;
  
        @include mq('large', 'max-width') {
          margin-top: var(--emu-common-spacing-brand-xs);
        }
      }
  
      &__filter-cta-container {
        @include mq('large') {
          margin-top: 25px;
        }
      }
    }
  
    .pre-footer .intro-box {
      @include mq('large', 'max-width') {
        padding-top: 37px;
      }
    }
  }