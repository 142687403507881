.faq {
  padding-bottom: var(--emu-common-spacing-none);

  &__section--left {
    padding-top: 61px;
    padding-bottom: var(--emu-common-spacing-brand-small);
    overflow: hidden;

    @include mq('large') {
      padding-top: 59px;
    }
  }

  &__section--right {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);

    @include mq('large') {
      padding-bottom: var(--emu-common-spacing-none);
      border-left: var(--emu-common-border-width-thin)
        var(--emu-common-colors-black) solid;
      padding-top: var(--emu-common-spacing-brand-medium);
    }

    .button .faq__toggler.emu-button.emu-button-v2 {
      margin-bottom: var(--emu-common-spacing-medium);
      padding-right: var(--emu-common-spacing-medium);

      @include mq('large') {
        padding-right: var(--emu-common-spacing-brand-medium);
        margin-top: var(--emu-common-spacing-none);
      }
    }
  }

  &__title {
    @include mq(large) {
      .cmp-title__text {
        font-size: 56px; // no variable available for this
        line-height: var(--emu-common-line-heights-custom-xs);
        letter-spacing: -0.68px;
      }
    }
  }

  &__accordion {
    .aaaem-accordion__header {
      @include mq('large') {
        padding-top: 34px;
        padding-bottom: 29px;
        min-height: unset;
      }

      .plus-minus-icon {
        .aaaem-accordion__icon {
          @include mq('large') {
            align-self: center;
          }
        }

        .aaaem-accordion__title {
          line-height: var(--emu-common-line-heights-narrow-large);
          letter-spacing: normal;

          @include mq('large') {
            line-height: var(--emu-common-line-heights-custom-regular);
            letter-spacing: -0.2px;
          }
        }
      }
    }

    &-button {
      margin-top: var(--emu-common-spacing-large);

      @include mq('large') {
        margin-top: 30px;
      }
    }
  }

  &__filter-cta {
    &.emu-button.emu-button-v2.emu-button.emu-button {
      @include mq('large') {
        padding-bottom: 13px;
        padding-top: 11px;
      }
    }

    &--active.emu-button.emu-button-v2.emu-button.emu-button {
      background-color: var(--emu-common-colors-black);
      color: var(--emu-common-colors-white);
      border-color: var(--emu-common-colors-black);
      outline: none;
    }

    &-container {
      padding-top: var(--emu-common-spacing-small);

      > .button {
        margin-left: 15.5px; // intentional to match the design

        @include mq('large') {
          margin-bottom: 18px;
        }
      }
    }
  }
}
