#about {
  .about {
    &__teaser {
      &.emu-teaser.emu-teaser-v3.inline-text-overlay.u-teaser--content-bottom {
        .content-container {
          padding-top: 38px;
          padding-bottom: 23px;

          @include mq('large') {
            padding-top: 60px;
            padding-bottom: 46px;
          }
        }
      }
    }

    &__science-intro {
      padding-top: 38px;
      padding-bottom: var(--emu-common-spacing-brand-xs);

      @include mq('large') {
        padding-top: 61px;
        padding-bottom: 79px;
        border-bottom: var(--emu-common-border-width-thin) solid
          var(--emu-common-colors-black);
      }
    }

    &__img-txt-grid {
      .img-txt-grid {
        &__content {
          padding-top: 37px;

          &--top {
            border-top: var(--emu-common-border-width-thin) solid
              var(--emu-common-colors-black);

            @include mq('large') {
              padding-top: 66px;
              border-top: var(--emu-common-border-width-none);
            }
          }

          &--bottom {
            padding-bottom: 34.5px;

            @include mq('large') {
              padding-top: 55px;
              padding-bottom: var(--emu-common-spacing-brand-medium);
            }
          }
        }

        &__title {
          .cmp-title__text {
            @include mq('large') {
              letter-spacing: -0.7px;
            }
          }

          &-container {
            margin-bottom: 19px;

            @include mq('large') {
              margin-bottom: 14px;
            }
          }
        }

        &__disc {
          @include mq('large') {
            margin-top: var(--emu-common-spacing-brand-xs);
          }

          &-text {
            margin-bottom: 6px;

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-none);
              margin-top: 7px;
            }
          }

          &-title {
            margin-bottom: var(--emu-common-spacing-small);

            @include mq('large') {
              margin-bottom: var(--emu-common-spacing-none);
            }
          }
        }
      }

      &.img-txt-grid {
        &--reverse-in-mobile {
          .img-txt-grid {
            &__content--top {
              border-top: none;
            }

            &__disc-text {
              margin-bottom: var(--emu-common-spacing-brand-small);

              @include mq('large') {
                margin-bottom: var(--emu-common-spacing-none);
              }
            }
          }
        }
      }
    }

    &__juv-difference-intro {
      padding-top: 37px;
      padding-bottom: var(--emu-common-spacing-brand-xs);

      @include mq('large') {
        padding-top: 61px;
        padding-bottom: 78px;
        letter-spacing: -1px;
      }
    }

    &__three-card-grid {
      .brand-card {
        &__content {
          min-height: 500px; //as per design
          padding-top: 28px;

          @include mq('large') {
            min-height: 600px; //as per design
            padding-top: 45px;
          }
        }

        &__title {
          padding-top: 13px;

          @include mq('large') {
            padding-top: var(--emu-common-spacing-medium);
            margin-bottom: 11px;
          }
        }

        &__subtitle {
          margin-bottom: 12px;

          @include mq('large') {
            margin-bottom: 18px;
          }

          .cmp-title__text {
            letter-spacing: -0.1px;

            b {
              sup {
                font-size: 100%;
                top: 9px;
                right: 1px;

                @include mq('large') {
                  font-size: 60%;
                  top: 0;
                  right: 2px;
                }
              }
            }
          }
        }
      }
    }

    &__proven-intro {
      padding-top: var(--emu-common-spacing-brand-small);
      padding-bottom: var(--emu-common-spacing-brand-xs);
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-black);

      @include mq('large') {
        padding-top: 62px;
        padding-bottom: 76px;
      }
    }

    &__approved-grid {
      .img-txt-grid {
        &__content {
          &--top {
            padding-top: 37px;

            @include mq('large') {
              padding-top: 65px;
            }
          }

          &--bottom {
            padding-top: 38px;
            padding-bottom: 31px;

            @include mq('large') {
              padding-top: 54px;
              padding-bottom: var(--emu-common-spacing-brand-medium);
            }

            .button {
              .emu-button {
                margin-bottom: var(--emu-common-spacing-small);

                @include mq('large') {
                  margin-top: var(--emu-common-spacing-none);
                  margin-bottom: var(--emu-common-spacing-none);
                }
              }
            }
          }
        }

        &__title-container {
          margin-bottom: 19px;

          @include mq('large') {
            margin-bottom: 17px;
          }
        }

        &__disc {
          margin-top: var(--emu-common-spacing-brand-small);

          @include mq('large') {
            margin-top: var(--emu-common-spacing-brand-xs);
          }

          &-title {
            margin-bottom: var(--emu-common-spacing-small);

            @include mq('large') {
              margin-bottom: 7px;
            }
          }
        }
      }

      &.img-txt-grid--reverse-in-mobile {
        .img-txt-grid {
          &__disc-text {
            @include mq('large', 'max-width') {
              margin-bottom: var(--emu-common-spacing-medium);
            }
          }
        }
      }
    }
  }

  .pre-footer {
    @include mq('large', 'max-width') {
      margin-bottom: -2px; // to match the design
    }

    .intro-box {
      @include mq('large', 'max-width') {
        padding-top: 38px;
      }
    }

    .button .emu-button {
      margin-bottom: var(--emu-common-spacing-large);
    }

    .img-txt-grid__text {
      @include mq('large') {
        margin-bottom: 25px;
      }
    }
  }
}
